.team {
    color: var(--black);
    background: var(--text);
    border: 1px solid rgba(0,0,0,0)
}


.team .members {
    padding-bottom: 200px;
}
.team .member {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 0;
    margin-left: clamp(10px, 14%, 1000px);
    margin-right: clamp(10px, 14%, 1000px);
    margin-top: clamp(10px, 9%, 1000px);
    margin-bottom: clamp(10px, 9%, 1000px);
    border-bottom: 2px solid rgb(192, 192, 192);
    flex-wrap: wrap;
    padding-bottom: 80px;
}
.team .member .left {
    width: clamp(250px, 43%, 1000px);
    order: 0;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0;
}
.team .member .right {
    width: clamp(250px, 43%, 1000px);
    order: 1;
    text-align: left;
    line-height: 25px;
    margin-top: -24px;
}

.team .member img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    background: rgba(0,0,0,0);
    border-radius: 50%;
}
.team .member .left .name {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    font: var(--secondary-bold);
    flex-grow: 0;
    height: 40px;
    margin-top: 40px;
}
.team .member .left .name img {
    width: 40px;
    height: 40px;
    margin-left: 3px;
}
.team .member .left h2 {
    font: var(--tertiary-light)
}
/* alternateing left/right layout  */
@media screen and (min-width: 600px){
    .team .members .member:nth-child(even) .left {
        order: 1;
        align-items: end;
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
    }
    .team .members .member:nth-child(even) .right {
        order: 0;
    }
}

