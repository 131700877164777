.services {
    color: var(--black);
    padding: 0px;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
    font: var(--paragraph);
    line-height: 1.7em;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.services h1 {
    font: var(--primary-black);
}

.services .section {
    box-sizing: border-box;
    scroll-snap-align: start;
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding-left: clamp(10px, 20%, 200px);
    padding-right: clamp(10px, 20%, 200px);
    padding-bottom: 150px;
    padding-top: 50px;
    background-color: white;
    z-index: 10;
}

.services .section:nth-child(even) {
    background-color: var(--text);
}


@media screen and (max-width: 550px) {
    .services {
        font-size: 13px;
    }
    .services .section {
        min-height: 250px;
    }
}
::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}





/* ------------Customized intro backgrounds--------------- */

.remote-learning-intro {
    background: url('../../../static/img/Remote_background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.junior-academy-intro {
    background: url('../../../static/img/Junior_background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.screening-intro {
    background: url('../../../static/img/Screening_background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.coaching-intro {
    background: url('../../../static/img/Coaching_background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width: 600px) {
    .remote-learning-intro {
        background: url('../../../static/img/Remote_background_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .junior-academy-intro {
        background: url('../../../static/img/Junior_background_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .screening-intro {
        background: url('../../../static/img/Screening_background_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .coaching-intro {
        background: url('../../../static/img/Coaching_background_mobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

}