/*Contact Info Section start*/
.ContactInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 250x;
    top: 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.ContactInfo-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 250px;
    height: auto;
    left: 0px;
    top: 256px;
    flex: none;
    margin-bottom: 20px;
}

.ContactInfo-section div{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F1F1F1;
    text-align: left;
}

.ContactInfo-section p{
    text-align: left;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #F1F1F1;
}

.ContactInfo-Social{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 185px;
    height: 192px;
    left: 0px;
    top: 402px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.ContactInfo-SocialIcon{
    color: var(--white);
    margin-bottom: 30px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    gap: 20px;
}

.ContactInfo-SocialIcon .cIcon {
    cursor: pointer;
    color: var(--white);
    padding: 4px 4px 3px 4px;
    border: 1px solid var(--text);
}

.ContactInfo-SocialIcon .cIcon:hover {
    filter: contrast(0.4);
}


.ContactInfo-SocialIcon p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
}
/*Contact Info Section finished*/

/* Smaller laptop */
@media screen and (max-width: 1068px) {
    .ContactInfo-section{
        width: 200px;
        left: 0;
    }

}
