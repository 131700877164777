.StudentHomeMain {
    background: var(--background2);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.StudentHomeMain::-webkit-scrollbar {
    display: none;
}
/* hide second scrollbar in chrome */

.StudentHomeContainer {
    margin-top: 6%;
    max-width: 1410px;
    display: -webkit-inline-box;
}

.StudentHomeContainer ul {
    padding-inline-start: 0px;
}

/* Normal cards */

.StudentHomeCard {
    /* Auto Layout */
    display: inline-table;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: static;
    width: 240px;
    height: 339px;
    /* Inside Auto Layout */
    flex: none;
    flex-grow: 0;
    margin: 0px 0px;
}

.StudentHomeCardBoarder {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    margin: 30px;
    border: 2px solid #8AE3FF;
    cursor: pointer;
}

.StudentHomeCardBoarder:hover {
    box-shadow: 5px 5px 50px rgba(46, 131, 160, 0.2), -5px -5px 50px rgba(46, 131, 160, 0.2);
}


.StudentHomeCardText {
    /* Tertiary/light */
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    margin: 30px 0px;
}

.StudentHome-SocialIcon {
    color: #F1F1F1;
    margin-top: 76px;
    display: flex;
    width: 35px;
    height: 35px;
    margin-left: 76px;
}

/* Logout card */

.StudentHomeLogoutBoarder {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    margin: 30px;
    border: 2px solid #FFFFFF;
    cursor: pointer;
}

.StudentHomeLogout-Icon {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 80px;
}

.logout-button {
    margin-top: 20px;
}