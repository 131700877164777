/* Blog */
.blog_container {
    width: 70%;
    margin-left:15%;
}
.blog_small_item_row {
    margin-top: 100px;
    padding-bottom: 100px;
}
@media (max-width: 800px) {
    .blog_container {
        width: 90%;
        margin-left:5%;
    }
}

/* BlogItemSmall */
.blog_small_item_row_item {
    display: flex;
    text-align: left;

}
.blog_small_item_left_col {
    width: 50%;
    padding-right: 5%;
}
.blog_small_item_right_col {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.blog_small_item_right_col img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.blog_small_item_title {
    font-size: 25px;
    line-height: 40px;
    color: var(--black);
}
.blog_small_item_author {
    font-size: 15px;
    color: #828282;
}
.blog_small_item_date {
    font-size: 15px;
    color: #828282;
    margin-bottom: 10px
}
.blog_small_item_content {
    font-size: 18px;
    line-height: 30px;
}
.blog_small_item_content_text {

}
.blog_small_item_read_more {
    color: #5AC4EB;
    margin-left: 10px;
}
.blog_small_item_read_more:hover {
    cursor: pointer;
}
@media (max-width:800px) {
    .blog_small_item_row_item {
        display: grid;
    }
    .blog_small_item_left_col, .blog_small_item_right_col {
        width: 100%;
    }
    .blog_small_item_right_col {
        order: -1;
        margin-bottom: 30px;
    }
}



/* Blog Content */
.blog_content_image_banner {
    width: 100%;
    height: 500px;
    overflow: hidden;
}
.blog_content_image_banner img {
    width: 100%;
    margin-top: -5%;
}
.blog_content_main_container {
    width: 70%;
    margin-left: 15%;
    text-align: left;
    background: var(--text);
    min-height: 300px;
    padding-bottom: 200px;
}
.blog_content_title {
    margin-top: 100px;
    font-size: 25px;
    font-weight: 400;
    color: var(--black);
}
.blog_content_author {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #828282;
}
.blog_content_date {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #828282;
}
.blog_content_content {
    margin-top: 50px;
    font-size: 15px;
    font-weight: 400;
    color: #828282;
    white-space: pre-wrap;
    /* white-space here for text could start with a new line like in database */
}
.blog_content_share {
    cursor: pointer;
    margin-top: 70px;
    font-size: 15px;
    font-weight: 400;
    color: #828282;
    margin-bottom: 100px;
}
.blog_content_share:hover {
    position: relative;
    color: var(--primary);
    top: -3px;
}

/* loading skeleton */
.skeletonText{
    float: left;
    width: 400px;
    margin-top: 100px;
    margin-left: 200px;
}

.skeletonPic{
    float: right;
    margin-top: 90px;
    margin-right: 280px;
}

.loadingBody{
    margin-left: 200px;
    margin-top: 100px;
}

.loadingInfo{
    margin: 5px;
}