.HomePage {
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    overflow:hidden;
    z-index: 0;
    top:0;    
    background: var(--white);    
}

.HomePageBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: slide-in-left 2s ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
}

.HomePageBody h1 {
    position: relative;
    font: var(--boost-performance);
    width: 100%;
    padding-left: clamp(20px, 15%, 1000px);
    text-align: left;
    font-size: clamp(20px, 4.5vw, 150px); 
    color: var(--inactive);
    z-index: 5;
}


.HomePageBody h1 em {
    font: var(--boost-performance);
    font-size: clamp(20px, 10vw, 200px);
    color: var(--white);
}

.HomePageBody img {
    position: fixed;
    left: -1px;
    top: 0px;
    width: auto;
    height: 100vh;
    object-fit: cover;
    transform-origin: center;
    fill: var(--primary);
    z-index: 4;
}

.HomePage .golfer {
    width: 130vh;
    height: auto;
    position: fixed;
    bottom: -4vh;
    right: -11vw;
    animation: slide-in-right 2s ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    filter: hue-rotate(200deg);
}

.messageHome{
    position: static;
    width: auto;
    height: 20%;
    top: 298.5px;
    font-family: Poppins;
    font-style: italic;
    font-weight: 300;
    font-size: 60px;
    line-height: 105px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.01em;
    color: var(--white);
}


.HomePagelink{
    display: flex;
    flex-direction: row;
    padding: 12px 0px 12px 0px;
    gap: 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: var(--primary);
}

.HomeIcon{
    cursor: pointer;
    color: rgba(255,255,255,0.25);
    padding: 4px 4px 0px 4px;
    border: 1px solid rgba(255,255,255,0.25);
}
.HomeIcon:hover {
    color: var(--white);
}

@keyframes slide-in-left {
    100%{opacity: 1;}
    0%{ transform: translateX(-1000px); opacity: 0; }
}


@keyframes slide-in-right {
    100%{opacity: 1;}
    0%{ transform: translateX(1000px); opacity: 0; }
}

@media screen and (max-width: 600px){
    .HomePage{
        width: 100%;
        height: 100%;
    }

    .HomePageBody {
        animation-delay: 1.5s;
    }

    .HomePage .golfer{
        width: 120vw;
        height: auto;
        bottom: 20vw;
        right: -14vw;
    }
}

/* @keyframes expand {
    0% {clip-path: circle(100px at center);}
    30% {clip-path: circle(100px at center);}
    95% {clip-path: circle(100vh at center);}
    100% {clip-path: none;}
} */