/*Contact Input Section start*/
.ContactInput-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 250px;
    top: 0px;
    flex: none;
    order: 2;
    margin: 0px 30px;
}

.ContactInput-info{
    position: static;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #F1F1F1;
    flex: none;
    text-align: left;
}

.ContactInput input{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    position: static;
    width: 240px;
    height: 38px;
    background: rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    padding-left: 10px;
    color: var(--text);
}

.ContactInput textarea{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    position: static;
    width: 240px;
    height: 200px;
    background: rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    padding-left: 10px;
    padding-top: 10px;
    color: var(--text);
}

.ContactInput button{
    padding: 10px;
    position: static;
    width: 250px;
    height: 38px;
    background: rgba(0,0,0,0);
    border: 1px solid var(--white);
    color: #F1F1F1;
    flex: none;
    margin-top: 10px;
}
.ContactInput button:hover {
    background-color: var(--primary);
}

.ContactInput *:focus {
    outline: 1px solid var(--white);
    color: white;
}
/*Contact Input Section finished*/

/* Smaller laptop */
@media screen and (max-width: 1068px) {
    .ContactInput-section{
        width: 200px;
        left: 0;
    }
    .ContactInput input{
        width: 200px;
        margin-top: 20px;
    }
    
    .ContactInput textarea{
        width: 200px;
        height: 200px;
        margin-top: 20px;
    }
    
    .ContactInput button{
        width: 200px;
        margin-top: 20px;
    }
}
