.levels {
    position: relative;
    width: 100%;
    background-image: url('../../../../static/img/Levels_background.svg');
    background-size: cover;
    color: var(--background);
    text-align: left;
    padding-left: clamp(10px, 4%, 200px);
    padding-right: clamp(10px, 10%, 200px);
    color: inherit;
}

.levels * {
    position: fixed;
    transition: top ease 0.2s;
}

.levels h1 {
    transition: top linear 0s;
    font-size: 60px;
}

.levels p {
    font: var(--paragraph);
    transition: top linear 0s;
}


.levels .level  {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    width: 90vw;
    flex-grow: 0;
    flex-shrink: 0;
    background: #b2c5ee;
    border-radius: 10px;
    padding-left: 20px;
    /* color: var(--text); */
}

.levels .level h2 {
    position: relative;
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    font: var(--secondary-bold);
}

.levels .level p {
    position: relative;
    width: 100%;
    font: inherit;
    white-space: pre-wrap;
}

/* Level colors */
.levels .level:nth-child(3) {
    background: linear-gradient(90deg, rgb(255, 232, 28) 0%, rgba(255,255,255,1) 100%) ;
    /* background: rgb(249, 253, 11); */
    /* color: rgb(58, 58, 58); */
}
.levels .level:nth-child(4) {
    background: linear-gradient(90deg, rgb(250, 38, 38) 0%, rgba(255,255,255,1) 100%) ;
    /* background: rgb(250, 38, 38); */
}
.levels .level:nth-child(5) {
    background: linear-gradient(90deg, rgb(255, 148, 9) 0%, rgba(255,255,255,1) 100%) ;
    /* background: rgb(255, 148, 9); */
}
.levels .level:nth-child(6) {
    background: linear-gradient(90deg, rgb(34, 202, 48) 0%, rgba(255,255,255,1) 100%) ;
    /* background: rgb(30, 179, 42); */
}
.levels .level:nth-child(7) {
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 80%, rgba(0,0,0,0) 100%) ;
    color: var(--white);
}





@media screen and (max-width: 550px) {
    .levels {
        padding: 0 10px 0 10px;
    }

    .levels h1 {
        font-size: 54px;
    }

    .levels .level h2 {
        font: var(--tertiary-bold);
        width: 120px;
    }

    .levels .level p {
        font: var(--tiny-regular);
    }
}