.Profile{
    background: var(--background2);
    width: 100vw;
    height: auto;
    overflow-x: hidden;
}

.Profile::-webkit-scrollbar {
    display: none;
}
/* hide second scrollbar in chrome */

.ProfileContaniner{
    display: flex;
    flex-direction: column;
    padding: clamp(3px, 10%, 200px)
}

.ProfileBottom{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: static;
    width: 100%;
    flex-wrap: wrap;
    
}

.ProfileContactSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: clamp(270px, 40%, 450px);
    height: auto;
}

.ProfileSave{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    align-self: stretch;
    border: none;
    /* font */
    font-family: Montserrat;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

@media screen and (min-width: 1625px){
    .ProfileContaniner{
        padding-left: 20%;
    }
}