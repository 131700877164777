.tab_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    width: 100vw;
    height: 150px;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 0;
    z-index: 100;
}

.tab_container div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 0;
    font: var(--tertiary-light);
    text-align: center;
    line-height: 30px;
    white-space: pre-wrap;
}
.tab_container div:hover{
    filter: contrast(0.9);
    position: relative;
    top: 2px;
}
.tab_container .active {
    background: inherit;
    font-weight: 600px;
}
.tab_container .inactive {
    background: inherit;
    box-shadow: inset 5px -3px 10px rgba(0, 0, 0, 0.15);
    filter: contrast(0.4);
    color:rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.1);
}