.bulletPoint {
    font: inherit;
    color: inherit;
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 0;
}

.bulletPoint span {
    font-size: 40px;
    font-weight: 500px;
    padding: 0px 10px 0 10px;
    position: relative;
    top: -3px;
    align-self: flex-start;
}
