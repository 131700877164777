.cover-enter {
    position: absolute;
    top: -100vh;
    width: 100vw;
    height: auto;
    padding-top: 100vh;
    background: var(--primary);
    animation-fill-mode: backwards;
    animation: cover-enter 0.8s ease-in-out;
    z-index: 1000;
}


@keyframes cover-enter {
    0% { top: 0;}
    100% { top: -100vh;}
}

