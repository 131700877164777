.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,500&family=Poppins:ital,wght@0,300;0,500;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@900&display=swap');
:root {
  /* Fonts */
  --boost-performance: normal normal 900 150px "Roboto";
  --primary-light: normal normal 300 65px "Montserrat";
  --primary-medium: normal normal 500 65px "Montserrat";
  --primary-bold: normal normal 700 65px "Montserrat";
  --primary-black: normal normal 900 65px "Montserrat";
  --secondary-light: normal normal normal 25px "Montserrat";
  --secondary-medium: normal normal 500 25px "Montserrat";
  --secondary-bold: normal normal 700 25px "Montserrat";
  --secondary-black: normal normal 900 25px "Montserrat";
  --tertiary-light: normal normal 500 15px "Montserrat";
  --tertiary-medium: normal normal 500 15px "Montserrat";
  --tertiary-bold: normal normal bold 15px "Montserrat";
  --tiny-regular: normal normal normal 10px "Montserrat";
  --paragraph: normal normal normal 15px "Montserrat";

  /* Colors */
  --background4:#03225c;
  --background3:#0c2b66;
  --background2:#0c2e6e;
  --background:#0c2e6e;
  --text: #F1F1F1;
  /* --primary: #00AAE7; */
  --primary: #0062cc;
  --secondary: #8AE3FF;
  --inactive: #dddddd;
  --error: #EE8700;
  --palette1: #F2C94C;
  --palette2: #6FCF97;
  --palette3: #EB5757;
  --palette4: #757575;
  --palette5: #626262;
  --palette6: #424242;
  --palette7:  #575A65;
  --palette8: #000000;
  --schedule1: #6FCF97;
  --schedule2: #27AE60;
  --schedule3: #1A7541;
  --dark-blur: rgba(0, 0, 0, 0.3);
  --top-shadow: linear-gradient(180deg, rgba(0, 0, 0, 0.49) 0%, rgba(17, 17, 17, 0) 83.33%, rgba(17, 17, 17, 0) 100%);
  --white: #FFFFFF;
  --gray: #828282;
  --black: #000000;
}
