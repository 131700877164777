.ProfileHeader{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    position: static;
    width: auto;
    height: auto;
    margin: 60px 0px;
    flex-wrap: wrap; 
}

.ProfileAvatar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 200px;
    height: 200px;
    background: whitesmoke;
    border-radius: 300px;
}

.ProfileTite{
    position: static;
    font-family: Montserrat;
    font-size: 15px;
    line-height: 18px;
    color: var(--secondary);
    margin: 18px 0px;
    text-align: left;
}

.ProfileName{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 200px;
    border: 0.5px solid #aaaaaa;
    position: static;
    font-family: Montserrat;
    font-size: 25px;
    line-height: 30px;
    color: #F1F1F1;
    margin: 18px 0px;
    background: var(--background2);
    border: none;
}

.ProfileNameSection{
    padding-left: 46px;
}

::placeholder { /* Most modern browsers support this now. */
    color:  #989c9f;
 }