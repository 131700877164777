/* Result */
.result_container .css-heg063-MuiTabs-flexContainer {
    display: block!important;
}

.result_container .tab {
    color: var(--inactive);
    font: var(--tertiary-medium);
    margin: 20px;
}

.result_container {
    background: var(--background2);
    color: white;
    height: 100vh;
}
.result_inner_container {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 200px;
}
@media (max-width: 800px) {
    .result_inner_container {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        font-size: 15px;
    }
}


/* Result Inner */
.result_inner_row {
    font-size: 20px;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}
.result_inner_file_icon {
    width: 15%;
    text-align: left;
    color: white;
}
.result_inner_file_name {
    width: 35%;
    text-align: left;
    padding-top: 20px;
}
.result_inner_file_date {
    width: 35%;
    text-align: right;
    padding-top: 20px;
}
.result_inner_file_download {
    width: 15%;
    text-align: right;
    padding-top: 20px;
}
.result_inner_file_icon svg {
    fill: transparent;
    stroke: white;
    font-size: 35px;
    padding-top: 20px;
}
.result_inner_file_download svg {
    fill: var(--inactive);
    cursor: pointer;
}
.result_inner_file_download svg:hover {
    fill: white;
}
@media (max-width: 800px) {
    .result_inner_row {
        font-size: 15px;
        margin-bottom: 10px;
    }
    .result_inner_file_icon{
        display: none;
    }
    .result_inner_file_name {
        width: 55%;
    }
    .result_inner_file_date {
        width: 30%;
    }
    .result_inner_file_download {
        width: 15%;
    }
}