.loginPage {
    background: #F1F1F1;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    padding-bottom: 100px;
}

.login-logo {
    width: clamp(80px, 30%, 150px);
    margin-top: 50px; 
}

.login-input-group {
    padding-top: 50px;
    width: clamp(200px, 70%, 450px);
}

.login-input {
    margin-bottom: 50px;
}

.login-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: clamp(200px, 70%, 450px);
    height: 38px;
    margin-top: 40px;
    cursor: pointer;
}

.login-forgotten {
    width: clamp(200px, 70%, 450px);
    height: 18px;
    /* Tertiary/light */
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    /* Theme/inactive */
    color: #C2C2C2;
    /* Inside Auto Layout */
    flex: none;
    order: 4;
    flex-grow: 0;
    margin-top: 50px;
    cursor: pointer;
}


.login-forgotten:hover{
    text-decoration: underline;
}