.intro {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 0;
    z-index: 1;
    white-space: pre-wrap;
    color: var(--text);
}

.intro * {
    width: clamp(250px, 50%, 1000px);
    line-height: 1.7em;
    text-align: left;
    animation: fade-in-top 3s ease;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    z-index: 3;
    white-space: pre-wrap;
}

.intro h1 {
    text-align: left;
    width: 100%;
    opacity: 1;
    animation: none;
    animation-delay: none;
    animation-fill-mode: none;
}


.intro img {
    width: 120%;
    height: 120%;
    position: absolute;
    right: -20px;
    bottom: 20px; 
    object-fit: cover;
    animation: fade-in-rotate 3s ease;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    z-index: 2;
}

.intro-noGraphic {
    text-align: left;
    white-space: pre-wrap;
}
.intro-noGraphic h1 {
    text-align: center;
}
.intro-noGraphic h3 {
    text-align: center;
    width: 100%;
    position: relative;
    top: 100px;
}

@keyframes fade-in-left {
    100%{opacity: 1;}
    0%{transform: translateX(-500px); opacity: 0;}
}

@keyframes fade-in-top {
    100%{opacity: 1;}
    0%{transform: translateY(-300px); opacity: 0;}
    
}

@keyframes fade-in-rotate {
    100%{opacity: 1;}
    0%{transform: rotateZ(40deg) translateY(500px); opacity: 0;}
}
