/* GLOBAL */
.partner_container {
    background: #F1F1F1;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-behavior: -webkit-smooth;
}



/* Navigation */
.partner_page_navigation_container {
    position: fixed;
    position: -webkit-fixed;
    /* navBar height is 65px */
    top: 65px; 
    padding: 25px 0 25px 0;
    width: 100%;
    background: white;
    z-index: 50;
}
.navigation_partner_image_container {
    height: 60px;
    width: auto;
    display: inline-block;
    padding: 0 2%;
}
.navigation_partner_image_container img {
    width: 100%;
    height: 100%;
}
.navigation_partner_image_container:hover {
    cursor: pointer;
}

@media (max-width:600px) {
    .navigation_container {
        display: none;
    }
    .partner_page_navigation_container {
        display: none;
    }
}


/* Main */
.partner_main_container {
    width: 80%;
    margin-left: 10%;
    text-align: left;
}
@media (max-width:600px) {
    .partner_main_container {
        padding-top: 0;
    }
}


/* PartnerDetail */
.partner_detail_container {
    padding-top: 250px;
    padding-bottom: 50px;
    border-bottom: 1px solid #C2C2C2;
    line-height: 30px;
}
.partner_detail_logo_container {
    margin-bottom: 50px;
}
.partner_detail_logo_container img {
    height: 90px;
}
.partner_detail_website_link_container {
    margin-top: 30px;
    width: 100%;
    text-align: right;
    color: var(--black);
    text-decoration: underline #8AE3FF;
}
.partner_detail_website_link_container:hover {
    cursor: pointer;
}
@media (max-width:600px) {
    .partner_detail_container:nth-child(1) {
        margin-top: 0;
        padding-top: 50px;
    }

}

.partner_skeleton {
    margin-top: 140px;
    padding-left: 110px;
    padding-right: 110px;
}



/* Customized asjustment: Golf Technologies Logo */
/* .navigation_partner_image_container:nth-child(6) {
    width: 10%;
    position: relative;
    top: -20px;
} */
