.langDialog {
    padding: 50px;
    font: var(--tertiary-light);
    color: var(--background);
}


.langDialog-Buttongroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.langDialog-Button {
    /* button */
    cursor: pointer;
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 224.5px;
    height: 50px;
    background: #C2C2C2;
    /* Theme/white */
    border: 1px solid var(--white);
    box-sizing: border-box;
    margin: 10px;
    /* Inside Auto Layout */
}
.langDialog-Button:hover {
    filter: contrast(0.8);
}

.blue{
    background: var(--primary);
}

.langDialog-Button-text {
    /* Secondary/light */

    /* Theme/white */
    color: var(--white);
}

.languageSet{
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.languageSet-label {
    margin: 0px 3px;
}

.languageSet-label:hover{
    cursor: pointer;
}

.languageSet-currentLabel {
    color: var(--secondary);
    margin: 0px 3px;
}


.languageSet p {
    width: 30px;
    border: 1px solid #F1F1F1;
    transform: rotate(-90deg);
    margin: 0px 15px;
}


@media screen and (max-width: 1025px){
    .languageSet{
        flex-direction: column;
    }
    .languageSet p{
        display: none;
    }
    .languageSet-label{
        margin-top: 5px;
    }



}