/*Contact Name Section start*/
.ContactName-section{
    /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 50px;

    width: 250px;
    top: 0px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 30px;
}

.ContactName-name{
    /* Customer service */
    position: static;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 61px;
    color: #F1F1F1;
    text-align: left;
}

.nameline{
    /* Line 25 */
    position: static;
    width: 80px;
    opacity: 0.6;
    /* Theme/secondary */
    border: 4px solid var(--white);
    /* Inside Auto Layout */
    margin: 20px 0px;
}
/*Contact Name Section finished*/

/* Smaller laptop */
@media screen and (max-width: 1068px) {
    .ContactName-section{
        width: 200px;
        left: 0;
    }
}
