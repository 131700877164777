.schedule{
    width: clamp(600px, 80%, 1000px);
    height: 80%;
}

.schedulePage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background2);
    padding-bottom: 200px;
}