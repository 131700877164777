
.module {
    cursor: pointer;
}

.module img {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 248px;
    height: 356px;
    background: var(--white);
    object-fit: cover;
    border: 2px solid #3f3f3f;
    box-sizing: border-box;
    box-shadow: -20px -4px 8px rgba(0, 0, 0, 0.45), 10px 10px 0px var(--black), inset 20px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px 0px 0px 10px;
}