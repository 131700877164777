.ProfileContactWrapper {
    width: 100%;
}

.ProfileContact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;

    width: 100%;
    height: auto;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.contactTitle{
    width: 100%;
    height: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: var(--text);
    flex: none;
    align-self: stretch;
    margin: 0;
}