.ProfileInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: static;
    width: clamp(270px, 40%, 450px);
    height: 580px;
}

.ProfileInputSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    position: static;
    width: 100%;
    height: 40px;
    flex: none;
    align-self: stretch;
    margin: 10px 0px;
}

.ProfileInput{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 0px 0px 10px;
    position: static;
    width: 90%;
    height: 38px;
    flex: none;
    margin: 0px 18px;
    border-bottom: 0.5px solid #C2C2C2;
    border-top:0px;
    border-left:0px;
    border-right:0px;
    background: var(--background2);
    color: var(--text);
    /* in the input section, put color will change the color of user input text */
}

.ProfileMustInput{
    position: static;
    width: 6px;
    height: 18px;
    font-family: Montserrat;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: inherit;
    margin: 10px 0px;
}

.ProfileSelect{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: static;
    width: 92%;
    height: 33px;
    flex: none;
    margin: 0px 18px;
    border: 0.5px solid #C2C2C2;
    background: var(--background2);

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-left: 4px;
    color: #C2C2C2;
}

.marginRight{
    margin-right: 18px;
}

.ProfileDateSelect{
    width: 295.5px;
    margin-left: 18px;
    background: var(--background2);
    border: 0.5px solid #C2C2C2;
    height: 33px;
    color: var(--text);
    padding-left: 10px;
}

.ProfileDOBInput{
    width: 130px;
    font-size: 15px;
    color: var(--text);
}

/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
    -webkit-appearance: none;
    cursor: pointer;
    padding-right: 10px;
}