/* Default NavBar layout and colors */
.navBar {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    color: rgb(190, 190, 190);
    
    /* This relates to the stacking issue with Student Module page */
    /* Issue @00002 [12/oct/2021 @Sarah] */
    z-index: 100; 
}

.menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 70px 15px 70px;

    position: relative;
    width: 100%;
    height: 65px;

    background: var(--primary);
    color: inherit;
    font: var(--tertiary-light);
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}


.menuItem {
    display: flex;
    align-items: center;
    flex-grow: 0;
}

.menuItem:hover {
    cursor: pointer;
    position: relative;
    top: 3px;
}

.menuItems-middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 30px;

    flex-grow: 0;
    flex-wrap: wrap;
    
}

.menuItems-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
    gap: 10px;
    flex-grow: 0;
    height: 100%;
}


/* ---------------------------Mobile Menu-------------------------------- */
.menuItems-mobile-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    animation: navbar 1s ease;
    animation-fill-mode: forwards;
    opacity: 0;
}

.menuItems-mobile { 
    height: 100%;
    width: 80%;
    background-color: var(--primary);
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    box-shadow: 6px 0px 5px rgba(0, 0, 0, 0.15);
    z-index: 100;
}
.menuItem-mobile {
    cursor: pointer;
    color: rgb(190, 190, 190);
    width: 100%;
    text-align: center;
    padding: 20px 0px 20px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.menuItems-mobile .menuItem-mobile:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.menuItem-mobile:hover {
    filter: contrast(0.6);
}

@keyframes navbar {
    0% {left: -100%}
    100% {left: 0; opacity: 1}
}

/* ---------------------------------------------------------------- */




/* -------------------------  ICON  -------------------------------- */

.navBar .icon {
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    color: currentColor;
    cursor: pointer;
}  

.navBar .icon:hover {
    position: relative;
    top: 3px;
}

.navBar .menuIconWrapper {
    display: none;
}

.logo {
    width: 30px;
    height: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    background-image: url('../../static/img/logo/white.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.menuIconWrapper {
    width: 100px;
}
.leftIconWrapper {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}









/* -------------------------- STYLING FRO SOME SPECIAL PAGES ------------------------------- */

.cover{
    z-index: 99;
    position:absolute;
}

.light .menu {
    background-color: var(--primary);
    /* background-color: var(--background); */
}

/* When at the 'Home' page or 'Contact' page */
.transparent {
    /* background: rgba(0, 0, 0, 0);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0); */
}
.transparent .menu {
    /* background-color:rgba(0, 0, 0, 0);
    color: var(--primary); */
}

.transparent .logo {
    /* background-image: url('../../static/img/logo/blue.svg'); */
}


/* When at the 'Student home' page or its subpages */
.dark .menu {
    background-color: var(--primary);
    /* background-color: var(--background); */
}








@media screen and (max-width: 1100px) {
    .menu {
        padding: 10px;
    }

    .menuItems-middle {
        display : none;
    }

    .navBar .menuIconWrapper {
        display: flex;     
    }
}