/* Whole page */
.LandingPage{
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    /* background: url('../../static/img/.png'); */
    background: var(--white);
    background-size: cover;
    background-position: bottom;
    animation: clear 3s ease;
    animation-fill-mode: forwards;
}
.LandingPage video {
    object-fit: cover;
    width: 100vw;
    height: auto;
    position: fixed;
    top: -18vw;
    left: 0;
}

.LandingPage-mask{
    background: rgba(0,0,0,0);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
}

/* icon */
.LandingPageicon{
    width: 130px;
    padding: 10px;
}

.LandingPageicon img{
    width: 100%;
    
}

.LandingPageheader{
    height: 45%;
}


/* button */
.buttonArea{
 /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    gap: 70px;
}

.enterButton{
    display: flex;
    justify-content: center;
    margin: 0px 0px;
}

.landingButton{
    display: flex;
    align-items: center;
    padding: 16px;
    background: rgba(0,0,0,0);
    border: 1px solid var(--primary);
    margin-right: 30%;
    margin-left: 30%;
    color: var(--primary);
}

.landingButton:hover{
    /* background: var(--text);
    color: var(--black); */
    transform: scale(1.1);
}


.landingButton span{
    width: 199px;
    font: var(--tertiary-light);
    color: inherit;
    margin: 0px 10px; 
}

.languagesection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.languagesection a{
    font: var(--tertiary-light);
    line-height: 30px;
    color: var(--gray);
    margin: 0px 20px;
}

.languagesection a:hover{
    color: var(--primary);
    cursor: pointer;
}


.languagesection p{
    width: 20px;
    border: 1px solid var(--gray);
    transform: rotate(-90deg);
    margin: 0px 15px;
    color: var(--primary);
}
.languagesection .highlight {
    color: var(--primary);
    font: var(--tertiary-bold);
}

/* -----------------------Animation--------------------------------- */
.LandingPageicon {
    position: relative;
    opacity: 0;
    animation: top-enter 2.5s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    color: var(--primary);
}
.landingButton:nth-child(odd){
    position: relative;
    opacity: 0;
    animation: left-enter 2.5s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}
.landingButton:nth-child(even){
    position: relative;
    opacity: 0;
    animation: right-enter 2.5s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}
.languagesection a:nth-child(1) {
    position: relative;
    opacity: 0;
    animation: top-enter 2s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}
.languagesection p:nth-child(2) {
    position: relative;
    opacity: 0;
    animation: top-enter 2s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.6s;
}
.languagesection a:nth-child(3) {
    position: relative;
    opacity: 0;
    animation: top-enter 2s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.8s;
}
.languagesection p:nth-child(4) {
    position: relative;
    opacity: 0;
    animation: top-enter 2s ease;
    animation-fill-mode: forwards;
    animation-delay: 1.9s;
}
.languagesection a:nth-child(5) {
    position: relative;
    opacity: 0;
    animation: top-enter 2s ease;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}
@keyframes clear{
    0% {filter: blur(10px);}
    100% {filter: blur(0);}
}
@keyframes top-enter {
    0% {top: 200px; opacity: 0;}
    100% {top: 0px; opacity: 1;}
}

@keyframes left-enter {
    0% {right: 500px; opacity: 0;}
    100% {right: 0px; opacity: 1;}
}

@keyframes right-enter {
    0% {left: 500px; opacity: 0;}
    100% {left: 0px; opacity: 1;}
}
/* ---------------------------------------------------------------- */

@media screen and (max-width: 1025px){
    .LandingPage{
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .LandingPageicon img{
        float: none;
        margin: 0;
        margin-top: 30px;
    }
       
    .buttonArea {
        gap: 40px;
    }
    .enterButton{
        flex-direction: column;
        align-items: center;
    }
    
    .landingButton{
        margin-top: 5%;
    }
    
    .languagesection{
        flex-direction: column;
        margin-top: 5%;
    }
    
    .languagesection a{
        margin: 2%;
    }

    
    .languagesection p{
        display: none;
    }
}

