/* Video Library */
.video_library_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    min-height: 100vh;
    background: var(--background2);
    padding: 50px;
    flex-wrap: wrap;
    overflow-x: hidden;
}
.video_library_item_container {
    width: 220px;
    float: left;
    margin: 0 4% 50px 4%;
}
@media (max-width: 600px) {
    .video_library_container {
        padding: 50px;
        justify-content: center;
    }
}



/* Video Library Item*/
.video_library_item_container_inner {
    width: 100%;
    background: white;
    padding: 50px 5% 20px 5%;
}
.video_library_image_container {
    cursor: pointer;
    height: 150px;
}

.video_library_image_container video {
    width: 100%;
    height: 100%;
}

.video_library_image_container svg {
    position: relative;
    stroke: var(--inactive);
    fill: transparent;
    top: -100px;
    font-size: 50px;
    z-index: 10;
}
.video_library_image_container svg:hover {
    transform: scale(1.1);
}
.video_library_title_container div, .video_library_date_container div {
    width: 50%;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.video_library_title_container, .video_library_date_container {
    display: flex;
}
.video_library_title_left_col, .video_library_date_left_col {
    text-align: left;
}
.video_library_title_right_col, .video_library_date_right_col {
    text-align: right;
}


/* ------- Video Player Related  ----------- */

.video_library_image_container .backdrop {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12;
    background: rgba(0, 0, 0, 0.7);
}

.video_library_image_container .player {
    position: fixed;
    top: 80px;
    left: 15vw;
    width: 70vw;
    height: auto;
    z-index: 15;
}

@media (max-width: 800px) {
    .video_library_image_container .player {
        top: 150px;
        width: 90vw;
        left: 5vw;
    }
}

