/* GLOBAL */
* {
    font-family: Montserrat;
}
html {
    scroll-behavior: smooth;
}
.in_line_flex {
    display: inline-flex;
}
.flex {
    display: flex;
}
a {
    text-decoration: none;
    color: unset;
}
.clear_left {
    padding-left: 0!important;
}
.clear_right {
    padding-right: 0!important;
}
.mobile_hide {
    display: none;
}

@media (max-width: 600px) {
    .mobile_grid {
        display: grid;
    }
}
/* Navigation */
.navigation_container {
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    color:#828282;
    position: sticky;
    top: 75px;
    width: 100%;
    background: white;
    border-bottom: 1px solid #1A69AB;
}
.seperator_right {
    border-right: 1px solid #828282;
    padding-right: 20px;
    margin-right: 20px;
}
.navigation_item {
    display: inline;
}
.navigation_item:hover {
    color:black;
    cursor: pointer;
}
@media (max-width:600px) {
    .navigation_container {
        text-align: center;
    }
    .navigation_container .seperator_right {
        border-right: none;
        padding-right: unset;
        margin-right: unset;
        margin-bottom: 10px;
    }
}

/* BannerImage*/
.banner_image_container {
    width: 100%;
    margin-bottom: 110px;
}
.banner_image_container img.banner_image {
    width: 100%;
}


/* Main */
.main_container {
    width: 90%;
}
.left_column, .right_column {
    width: 50%;
}
.left_column {
    margin-left: 15%;
    font-size: 25px;
    color:var(--background);
    text-align: left;
}
.title_underlined {
    padding-bottom: 10px;
    border-bottom: 2px solid #8AE3FF;
}
.paragraph {
    font-size: 15px;
    color: var(--background);
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-bottom: 50px;
}
.paragraph .highlight_text {
    font-weight: bolder;
}
.what_we_do_container {
    margin-bottom: 50px;
}
.location_container {
    margin-bottom: 100px;
}

@media (max-width: 600px){
    .left_column, .right_column {
        width: 90%;
        margin-left: 10%;
    }
    .left_column {
        margin-bottom: 50px;
    }
    .title_underlined {
        line-height: 50px;
    }
}

/* WhatWeDo */



/* Location */
.location_map_container {
    text-align: left;
}
.location_map_container iframe{
    width: 100%;
    height: 300px;
}


/* Associate Service Providers */
.associate_service_providers_image_container {
    width: 100%;
}
.associate_service_providers_image_item {
    padding: 0 8%;
    width: 33.33%;
}
.associate_service_providers_image_item img {
    width: 100%;
}