.ContactPage{
    background: url('../../static/img/Contact_background.png');
    align-items: center;
    /* position: absolute; */
    width: 100vw;
    height: 100vh;
    background-size: cover;
    overflow-x: hidden;
    /* top: 0; */
    z-index: 0;
}


.ContactUsSectionBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 150px 0 150px 0;
    /* backdrop-filter: blur(5px); */
    background-color: rgba(0,0,0,0.5);

    position: relative;
    width: 100%;
    height: 100%;
    /* Note: backdrop-filter has minimal browser support */
    flex: none;
    flex-wrap: wrap;
    gap: 50px;
}

@media screen and (max-width: 750px) {
    .ContactUsSectionBox{height: auto;}
}